import React from 'react';

class Services extends React.Component {

    render() {
        return (
            <>

                <section className="hero-wrap hero-wrap-2 services-background">
                    <div className="overlay"></div>
                    <div className="container">
                        <div className="row no-gutters slider-text align-items-end justify-content-center">
                            <div className="col-md-9 pb-5 text-center" data-aos="fade-up" data-aos-delay="0">
                                <h1 className="mb-3 bread">Services</h1>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="ftco-section ftco-no-pt ftco-no-pb">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 pt-4">
                                <div className="text align-start" data-aos="fade-up" data-aos-delay="0">
                                    <p>It is important to keep your trees and hedges healthy and strong. Contact me for a free estimate.</p>
                                </div>
                            </div>
                        </div>
                        <div id="trimming" name="trimming" className="row mb-3">
                            <div className="col-12">
                                <div className="text align-start justify-content-center services-section" data-aos="fade-up" data-aos-delay="20">

                                    <h2 className="mb-4">Tree and shrub trimming</h2>
                                    <p> Trimming and pruning your trees or shrubs helps them to remain healthy and beautiful. Your trees and shrubs are a valuable asset to your property. Many of them took decades to get where they are today.</p>
                                    <p>Take care of them and they will be around for you and those that follow to enjoy. Ignore them and they will go away.</p>
                                    <div className="hero-wrap hero-wrap-3 trimming-image-container pb-5">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="stump" name="stump" className="row mt-5">
                            <div className="col-12">
                                <div className="text align-start services-section" data-aos="fade-up" data-aos-delay="20">
                                    <h2 className="mb-4">Stump removal</h2>
                                    <p>Professional stump removal will allow you to relandscape your yard without having to plan your landscape around the stump.</p>
                                    <div className="hero-wrap hero-wrap-3 stump-image-container pb-5">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="ash" name="ash" className="row mt-5">
                            <div className="col-12">
                                <div className="text align-start services-section" data-aos="fade-up" data-aos-delay="20">
                                    <h2 className="mb-4">Ash tree treatment: Infuse it or lose it</h2>
                                    <div className="hero-wrap hero-wrap-3 beetle-image-container pb-5 mb-5">
                                    </div>
                                    <div className="row mt-2 mb-5 no-gutters">
                                        <div className="col-12 col-md-6">

                                            <p>Roger's Tree Service injects emamectin benzoate to protect your ash tree.</p>
                                            <p>This product is applied by injecting the product directly into the trunk of the tree near the base. This systemic insecticide is evenly distributed throughout the canopy by the pull created by the leaves.</p>
                                            <p>When the insects ingest the chemical, they quickly stop feeding and die. Even if the tree has in infestation of beetles, if it is not too advanced, the tree can recover.</p>
                                            <p>An injection is recommended once every 2 years.</p>

                                        </div>
                                        <div className="col-12 col-md-6">
                                        <div className="hero-wrap hero-wrap-3 injection-image-container pb-5">
                                    </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                <section className="ftco-intro bg-primary py-1">
                    <div className="container">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-5">
                                <h2>Contact me</h2>
                                <p>Please call me to discuss your tree and shrub needs.</p>
                            </div>
                            <div className="col-md-7 text-md-right">
                                <a href="tel://6516997022"><span className="contact-number">(651) 699-7022</span></a>
                            </div>
                        </div>
                    </div>
                </section>








            </>
        );
    }
}

export default Services;
import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

class Footer extends Component {


    constructor(props) {
        super(props);
        this.state = {
            isNavOpen: false
        }
        this.toggleNav = this.toggleNav.bind(this);
    }

    toggleNav() {
        this.setState({
            isNavOpen: !this.state.isNavOpen
        });
    }


    render() {
        return (
            <>
                <footer className="ftco-footer ftco-bg-dark ftco-section">
                    <div className="container">
                        <div className="row mb-5">
                            <div className="col-md-4 col-lg">
                                <div className="ftco-footer-widget mb-4">
                                    <h2 className="logo"><a href="/">Roger's <span>Tree</span> Service</a></h2>
                                    <p>A local family-owned business that provides quality tree care at a competitive price.</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg">
                                <div className="ftco-footer-widget mb-4 ml-md-5">
                                    <h2 className="ftco-heading-2">Services</h2>
                                    <ul className="list-unstyled">
                                        <li><Link to="/services#trimming" className="py-1 d-block"><span className="ion-ios-arrow-forward mr-3"></span>Tree and shrub trimming</Link></li>
                                        <li><Link to="/services#stump" className="py-1 d-block"><span className="ion-ios-arrow-forward mr-3"></span>Stump removal</Link></li>
                                        <li><Link to="/services#ash" className="py-1 d-block"><span className="ion-ios-arrow-forward mr-3"></span>Ash tree treatment</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg">
                                <div className="ftco-footer-widget mb-4">
                                    <h2 className="ftco-heading-2">Contact information</h2>
                                    <div className="block-23 mb-3">
                                        <ul>
                                            <li><span className="icon icon-map-marker"></span><div className="text align-start">2504 18th Ave E<br />North Saint Paul, MN 55109</div></li>
                                            <li><a href="tel://6516997022"><span className="icon icon-phone"></span><span className="text">(651) 699-7022</span></a></li>
                                            <li><a href="mailto:roger@rogertree.com"><span className="icon icon-envelope"></span><span className="text">roger@rogertree.com</span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center">
                            <p className="mb-0">Copyright &copy;{(new Date().getFullYear())} All rights reserved</p>
                            </div>
                        </div>
                    </div>
                </footer>
            </>

        )
    }
}

export default Footer;
import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AOS from 'aos';
import Header from './HeaderComponent';
import Footer from './FooterComponent';

// import { PRELOADER, SITEMENUCLONE } from './navigation';

import Home from './HomeComponent';
import Services from './ServicesComponent';
import About from './AboutComponent';
import Contact from './ContactComponent';

class Main extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {

    AOS.init({
      // initialise with other settings
      duration: 800,
      easing: 'slide',
      once: true
    });

    // PRELOADER();
    // SITEMENUCLONE();
  }



  render() {

    const HomePage = () => {
      return (
        <div>
          <Home />
        </div>
      );
    }




    return (
      <>
      <Header />
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/services" element={<Services />} />
            <Route exact path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </>
    );
  }
}

export default Main;

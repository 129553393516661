import React, { useEffect, useState } from 'react';
import {  useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

const Header = () => {

    const location = useLocation(); // once ready it returns the 'window.location' object
    const [url, setUrl] = useState(null);
    useEffect(() => {
      setUrl(location.pathname);
    }, [location]);

        return (

            <>
                <div className="container pt-5 pb-4">
                    <div className="row justify-content-between">
                        <div className="col-md-10 col-lg-8 order-md-last">
                            <div className="row">
                                <div className="col-md-6 text-center">
                                    <div className="navbar-brand">Roger's <span>Tree</span> Service</div>
                                </div>
                                <div className="col-md-6 d-md-flex justify-content-end mb-md-0 mb-3 align-items-center d-flex">
                                    <div className="header-sideline header-phone align-items-center justify-content-center">
                                        <a href="tel://6516997022">(651) 699-7022</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-lg-4 d-flex">
                            <div className="header-sideline d-flex align-items-center justify-content-center">
                                <div className="isa-cert-container">
                                    <div className="isa-cert-image-container">
                                        <img className="isa-cert-image" alt="ISA Certified Arborist" src="/images/isa.png" />
                                    </div>
                                    <div className="isa-cert-text-container">
                                        <p>Roger Gatz</p>
                                        <p>MN-0321A</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <nav className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">
                    <div className="container-fluid">

                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="fa fa-bars"></span> Menu
                        </button>
                        <div className="collapse navbar-collapse" id="ftco-nav">
                            <ul className="navbar-nav m-auto">
                                <li className={"nav-item" + (url === "/" ?" active" : "")}><Link to="/" className="nav-link">Home</Link></li>
                                <li  className={"nav-item" + (url === "/about" ?" active" : "")}><Link to="/about" className="nav-link">About</Link></li>
                                <li className={"nav-item" + (url === "/services" ?" active" : "")}><Link to="/services" className="nav-link">Services</Link></li>
                                <li className={"nav-item" + (url === "/contact" ?" active" : "")}><Link to="/contact" className="nav-link">Contact</Link></li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </>


        )
    }


export default Header;
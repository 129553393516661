import React, { Component } from 'react';
import Main from './components/MainComponent';
import './css/aos.css';
import './css/App.css';
import './css/flaticon.css';
import './css/icomoon.css';
import { BrowserRouter } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';

class App extends Component {

  render() {
    return (
      <ParallaxProvider>
        <BrowserRouter>
          <div className="App">
            <Main />
          </div>
        </BrowserRouter>
      </ParallaxProvider>
    );

  }
}

export default App;










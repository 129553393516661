import React, { useState } from 'react';

// disabled ReCAPTCHA code
// import React, { useState, useRef } from 'react';
// import ReCAPTCHA from "react-google-recaptcha"
// import axios from 'axios';

import { Formik, Field, Form } from 'formik';
import emailjs from 'emailjs-com'
import * as Yup from 'yup';
import MaskedInput from "react-text-mask";


const phoneNumberMask = [
    "(",
    /[1-9]/,
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
];

const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/

const ContactSchema = Yup.object().shape({
    from_name: Yup.string()
        .min(5, 'PLease enter full name.')
        .max(100, 'Name must be less than 100 characters')
        .required('Required'),
    from_email: Yup.string()
        .email('Invalid email'),
    from_phone: Yup.string()
        .required('Required')
        .matches(phoneRegExp, 'Enter a 10-digit phone number'),
    html_message: Yup.string()
        .min(10, 'PLease enter a longer message.')
        .max(1000, 'Message must be less than 1000 characters')
        .required('Required'),
});


export default function Contact() {

    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const [showMessageBox, setShowMessageBox] = useState(true);
    // const [showRecaptchaFail, setshowRecaptchaFail] = useState(false);
    const [messageError, setMessageError] = useState(false);


    async function SendEmail(object) {

        emailjs.send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, object, process.env.REACT_APP_EMAIL_USER_ID)
            .then(() => {
                setShowMessageBox(false);
                setMessageError(false);
                // setshowRecaptchaFail(false);
            }, (error) => {
                console.log(error.text)
            })

        // const token = captchaRef.current.getValue();

        // await axios.post('/.netlify/functions/server/api/post', { token })
        //     .then(res => {
        //         if (res.data === true) { //returned successful reCAPTCHA
        //             emailjs.send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, object, process.env.REACT_APP_EMAIL_USER_ID)
        //                 .then(() => {
        //                     setShowMessageBox(false);
        //                     setMessageError(false);
        //                     setshowRecaptchaFail(false);
        //                 }, (error) => {
        //                     console.log(error.text)
        //                 })
        //         } else {
        //             setshowRecaptchaFail(true);
        //             setShowMessageBox(true);
        //             captchaRef.current.reset();
        //             setIsSendingEmail(false);
        //         }
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     })
    }

    // const captchaRef = useRef(null);

    return (
        <>
            <section className="hero-wrap hero-wrap-2 contact-background">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row no-gutters slider-text align-items-end justify-content-center">
                    <div className="col-md-9 pb-5 text-center" data-aos="fade-up" data-aos-delay="0">
                            <h1 className="mb-3 bread">Contact Roger</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ftco-section bg-light" id="quote">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="wrapper">
                                <div className="row mb-5">
                                    <div className="col-md-4">
                                        <div className="dbox w-100 text-center">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <span className="fa fa-map-marker"></span>
                                            </div>
                                            <div className="row text-center">
                                                <div className="col-4 justify-content-end pr-0">
                                                    <p><span>Address:</span></p>
                                                </div>
                                                <div className="col-8 justify-content-center pl-0">
                                                    <p className="align-start text">2504 18th Ave E<br />
                                                        North Saint Paul, MN<br />55109</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="dbox w-100 text-center">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <span className="fa fa-phone"></span>
                                            </div>
                                            <div className="text">
                                                <p><span>Phone:</span> <a href="tel://6516997022">(651) 699-7022</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="dbox w-100 text-center">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <span className="fa fa-paper-plane"></span>
                                            </div>
                                            <div className="text">
                                                <p><span>Email:</span> <a href="mailto:roger@rogertree.com">roger@rogertree.com</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-md-7">
                                        <div className="contact-wrap w-100 p-md-5 p-4">
                                            <h3 className="mb-4">Contact Me</h3>
                                            <div className={showMessageBox ? "text align-start pb-2" : "hidden"}>Feel free to send me a message using the form below.</div>
                                            <div className={messageError ? "mb-4" : "hidden"}>
                                                I'm sorry, there was an error sending your message. Please contact me at the number above.
                                            </div>
                                            <div className={showMessageBox || messageError ? "hidden" : "mt-5 mb-5"}>
                                                Your message was sent, thank you!
                                            </div>
                                            <Formik
                                                initialValues={{
                                                    from_name: "",
                                                    from_email: "",
                                                    phone1: "",
                                                    html_message: "",
                                                }}
                                                validationSchema={ContactSchema}
                                                onSubmit={(values, actions) => {
                                                    setIsSendingEmail(true);
                                                    setTimeout(() => {
                                                        SendEmail(values);
                                                        actions.setSubmitting(false);
                                                        // captchaRef.current.reset();
                                                    }, 1000)
                                                }}
                                            >
                                                {({ errors, touched }) => (
                                                    <Form className={showMessageBox && !messageError ? "contactForm" : "hidden"}>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="label" htmlFor="from_name"> Full Name:</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="form-group">
                                                                    <Field name="from_name"
                                                                        placeholder="Name"
                                                                        className="form-control"
                                                                    />
                                                                    {errors.from_name && touched.from_name ? (
                                                                        <div className="error">{errors.from_name}</div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="label" htmlFor="from_phone"> Phone:</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="form-group">
                                                                    <Field
                                                                        name="from_phone">
                                                                        {({ field }) => (
                                                                            <MaskedInput
                                                                                {...field}
                                                                                mask={phoneNumberMask}
                                                                                id="from_phone"
                                                                                placeholder="Phone"
                                                                                type="text"
                                                                                className={
                                                                                    errors.from_phone && touched.from_phone
                                                                                        ? "form-control error"
                                                                                        : "form-control"
                                                                                }
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    {errors.from_phone && touched.from_phone && (
                                                                        <div className="error">{errors.from_phone}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="label" htmlFor="from_email">Email Address:</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="form-group">
                                                                    <Field name="from_email"
                                                                        type="email"
                                                                        placeholder="Email"
                                                                        className="form-control"
                                                                    />
                                                                    {errors.from_email && touched.from_email ? <div className="error">{errors.from_email}</div> : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="label" htmlFor="html_message">Message:</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <Field name="html_message"
                                                                        component="textarea"
                                                                        cols="30"
                                                                        rows="4"
                                                                        placeholder="Message"
                                                                        className="form-control"
                                                                    />
                                                                    {errors.html_message && touched.html_message ? (
                                                                        <div className="error">{errors.html_message}</div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <ReCAPTCHA
                                                                        sitekey={process.env.REACT_APP_SITE_KEY}
                                                                        ref={captchaRef}
                                                                    />
                                                                </div>
                                                                <div className={showRecaptchaFail ? "mb-4 fail-validation" : "hidden"}>
                                                                    reCAPTCHA check failed. Please try again.
                                                                </div>

                                                            </div> */}
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <input type="submit" className="btn btn-primary" value={isSendingEmail ? "Sending message..." : "Send message"} disabled={(isSendingEmail) ? "disabled" : ""}></input>
                                                                    <div className="submitting"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                    <div className="col-md-5 d-flex align-items-stretch">
                                        <div className="info-wrap w-100 p-5 img contact-form-image">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

import React from 'react';

class About extends React.Component {

    render() {
        return (
            <>

                <section className="hero-wrap hero-wrap-2 about-background" data-stellar-background-ratio="0.5">
                    <div className="overlay"></div>
                    <div className="container">
                        <div className="row no-gutters slider-text align-items-end justify-content-center">
                            <div className="col-md-9 pb-5 text-center" data-aos="fade-up" data-aos-delay="0">
                                <h1 className="mb-3 bread">About Roger</h1>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="ftco-section ftco-no-pt ftco-no-pb">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 pt-4">
                                <div className="text align-start" data-aos="fade-up" data-aos-delay="0">
                                    <p>Roger's Tree Service is a family business. From the estimate until the job is completed, I am there every step of the way.</p>
                                    <p>My "hands-on" approach to running my business has allowed me to offer quality trimming at a competitive price.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="text align-start" data-aos="fade-up" data-aos-delay="20">
                                    <h2 className="mb-4">Why do trees need trimming?</h2>
                                    <p> A well-trimmed tree is attractive, but more importantly, it will last longer. It's important to remove dead wood from trees because wood-boring insects and wood-decaying fungi live in dead wood and spread from dead to living tissue. It is this decaying process that marks the beginning of the end for many trees. Removing the dead wood slows this process dramatically.</p>
                                    <p>Thinning out the tree is also important so that wind can pass through without breaking off branches or blowing the entire tree down. Thinning also allows more sunlight to reach the lawn area below the tree, resulting in a thicker lawn.</p>
                                    <p>Heavy horizontal branches are lightened up where necessary and roof and power lines are given ample clearance. A good tree trimming job will last many years.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="text align-start" data-aos="fade-up" data-aos-delay="20">
                                    <h2 className="mb-4">What should I look for in a good tree trimmer?</h2>
                                    <p>In many cities, anyone can become a "licensed" tree trimmer as long as they pay local municipal fees to run a business. You may want more than that when it comes to hiring a tree service.</p>
                                    <p>Just because someone can climb a tree with a chain saw doesn't mean they know what to do (and what NOT to do) when they get there.</p>
                                    <div className="row">
                                        <div className="col-12 col-md-3 d-flex justify-content-center">
                                            <div className="isa-cert-about-container">
                                                <div className="isa-cert-about-image-container">
                                                    <img className="isa-cert-about-image" alt="ISA Certified Arborist" src="/images/isa.png" />
                                                </div>
                                                <div className="isa-cert-about-text-container">
                                                    <p>Roger Gatz</p>
                                                    <p>MN-0321A</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-9">
                                            <p><strong>Hiring a certified arborist is important.</strong></p>
                                            <p>A tree trimmer that has a certification from the International Society of Arboriculture is your assurance that you have hired a trained professional.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="ftco-intro bg-primary py-1">
                    <div className="container">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-6">
                                <h2>Contact me</h2>
                                <p>Please call me to discuss your tree and shrub needs.</p>
                            </div>
                            <div className="col-md-5 text-md-right">
                                <a href="tel://6516997022"><span className="contact-number">(651) 699-7022</span></a>
                            </div>
                        </div>
                    </div>
                </section>








            </>
        );
    }
}

export default About;
import React from 'react';
import 'animate.css';
import Carousel from 'nuka-carousel';
import { Parallax } from 'react-scroll-parallax';
import { AiOutlineArrowRight } from 'react-icons/ai';
import useWindowWidth from "../useWindowWidth";
import { HashLink as Link } from 'react-router-hash-link';

const Home = () => {

    const WindowWidth = useWindowWidth();


    return (
        <>


            <div className="hero-wrap hero-background" style={{ height: "100vh" }}>
                <div className="overlay"></div>
                <Parallax speed={-15}>
                    <div className="container">
                        <div className="row no-gutters slider-text align-items-center justify-content-start" style={{ height: "100vh" }}>
                            <div className="col-1 d-sm-block d-md-none"></div>
                            <div className=" col-10 col-md-5 hero-text" data-aos="fade-up" data-aos-delay="0">
                                <h2 className="subheading">Roger's Tree Service, Inc.</h2>
                                <h1>Accept No Substitutes</h1>
                                <p className="mb-4">Serving the Twin Cities for 30 years</p>
                                <p><Link to="/about" className="btn btn-primary mr-md-4 py-2 px-4">Learn more <span className="ion-ios-arrow-forward"></span></Link></p>
                            </div>
                        </div>
                    </div>
                </Parallax>
            </div>

            <section className="ftco-section ftco-no-pt">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 py-5 order-md-last">
                            <div className="heading-section" data-aos="fade-up" data-aos-delay="0">
                                <span className="subheading">Services</span>
                                <h2 className="mb-4">Roger's Tree Service</h2>
                                <p>Using a certified arborist like <strong>Roger's Tree Service</strong> will assure you of quality care for your valuable trees and shrubs.</p>
                                <p><Link to="/contact#quote" className="btn btn-primary py-3 px-4">Get a Quote</Link></p>
                            </div>
                        </div>
                        <div className="col-lg-9 services-wrap px-4 pt-5">
                            <div className="row pt-md-3">
                                <div className="col-md-4 d-flex align-items-stretch">
                                    <div className="services text-center">
                                        <div className="icon d-flex justify-content-center align-items-center">
                                            <span className="flaticon-tree"></span>
                                        </div>
                                        <div className="text">
                                            <h3>Tree and shrub trimming</h3>
                                            <p>Trimming and pruning your trees and shrubs will keep them healthy and beautiful.</p>
                                        </div>
                                        <Link to="/services#trimming" className="btn-custom d-flex align-items-center justify-content-center"><span><AiOutlineArrowRight /></span></Link>
                                    </div>
                                </div>
                                <div className="col-md-4 d-flex align-items-stretch">
                                    <div className="services text-center">
                                        <div className="icon d-flex justify-content-center align-items-center">
                                            <span className="flaticon-tree-stump"></span>
                                        </div>
                                        <div className="text">
                                            <h3>Stump removal</h3>
                                            <p>Removing stumps will allow you to relandscape in place of that ugly stump.</p>
                                        </div>
                                        <Link to="/services#stump" className="btn-custom d-flex align-items-center justify-content-center"><span><AiOutlineArrowRight /></span></Link>
                                    </div>
                                </div>
                                <div className="col-md-4 d-flex align-items-stretch">
                                    <div className="services text-center">
                                        <div className="icon d-flex justify-content-center align-items-center">
                                            <span className="flaticon-beetle"></span>
                                        </div>
                                        <div className="text">
                                            <h3>Ash tree treatment</h3>
                                            <p>Protecting your ash tree against the Emerald Ash Borer is a wise investment.</p>
                                        </div>
                                        <Link to="/services#ash" className="btn-custom d-flex align-items-center justify-content-center"><span><AiOutlineArrowRight /></span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ftco-intro bg-primary py-5">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-6">
                            <h2>Contact me</h2>
                            <p>Please call me to discuss your tree and shrub needs.</p>
                        </div>
                        <div className="col-md-5 text-md-right">
                            <a href="tel://6516997022"><span className="contact-number">(651) 699-7022</span></a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ftco-section">
                <div className="container">
                    <div className="row justify-content-center mb-5">
                        <div className="col-md-10 text-center heading-section" data-aos="fade-up" data-aos-delay="0">
                            <h2 className="mb-4">Seasonal Tree Care Tips</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Carousel
                                wrapAround
                                cellAlign="center"
                                slidesToShow={WindowWidth < 800 ? 1 : 3}
                                slideIndex={WindowWidth < 800 ? 0 : 1}
                                cellSpacing={20}
                                renderCenterLeftControls={({ previousSlide }) => (
                                    <button onClick={previousSlide}>
                                        <i className="fa fa-arrow-left" />
                                    </button>
                                )}
                                renderCenterRightControls={({ nextSlide }) => (
                                    <button onClick={nextSlide}>
                                        <i className="fa fa-arrow-right" />
                                    </button>
                                )}
                            >
                                <div className="item">
                                    <div className="wrap">
                                        <div className="seasonal img d-flex align-items-center justify-content-center seasonal1-background">
                                        </div>
                                        <div className="text text-center px-4">
                                            <h3>Spring</h3>
                                            <p>Spring is an ideal time to protect your Ash trees from Emerald Ash Borer, the invasive insect that has killed thousands of mature shade trees.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="wrap">
                                        <div className="seasonal img d-flex align-items-center justify-content-center seasonal2-background">
                                        </div>
                                        <div className="text text-center px-4">
                                            <h3>Summer</h3>
                                            <p>Summer months are an excellent time to address your tree and shrub maintenance. It is an ideal time to shape them up so your yard looks great for outdoor activities.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="wrap">
                                        <div className="seasonal img d-flex align-items-center justify-content-center seasonal3-background">
                                        </div>
                                        <div className="text text-center px-4">
                                            <h3>Fall</h3>
                                            <p>Dormant pruning begins in the fall.  Some trees respond best to dormant pruning.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="wrap">
                                        <div className="seasonal img d-flex align-items-center justify-content-center seasonal4-background">
                                        </div>
                                        <div className="text text-center px-4">
                                            <h3>Winter</h3>
                                            <p>Some species of trees require winter pruning due to disease and insect problems that they can develop during the growing season.</p>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default Home;